import axios from "axios";

//  const API_URL = 'https://apilava.thecoderspottest.com/api/v1/admin/';
//  const API_URL = 'http://localhost:3000/api/v1/admin/';
 const API_URL = 'https://api.globalcoatings.app/api/v1/admin/';
// localhost
// const API_URL = "http://localhost:3000/api/v1/admin/";

export const GOOGLE_MAP_KEY = 'AIzaSyB2extZ7r3WnxIgwLY83LV5cZSqFb5h7N8';

export const GOOGLE_LIBRARIES = ['places'];

export const numbersCheck= /^[+]?[0-9]+$/;
export const characterCheck = /^[A-Za-z_ ]+$/;

export function setAxiosDefault(){
	return getToken()
	.then(resp => {
		console.log('resp',resp)
		var token = resp;
		axios.defaults.headers.common['Authorization'] = token;
		axios.defaults.headers.common['Content-Type'] = "multipart/form-data";
		
		return token
	}).catch(err => {
        console.log('error in setAxios', err)
	});
}

const handleError = (error) => {
	console.log(error);
	//console.log(error);
    if(error.response.status == 400) {
        return;
    }
	if(error.response.status == 413) {
		console.log(error)
        return;
    }
	if(error.response.status == 401){
		console.log('logged out');
		// let route = useRoute();
		// let redirectRoute = (RootNavigation.getCurrentRoute()).name;
		//return setToken("")
        localStorage.setItem("lava20_admin", '');
		document.location.reload();
		return;
		// .then(resp => {
			// var token = resp;
			// axios.defaults.headers.common['Authorization'] = "Bearer "+token;
			//source.cancel('optional message')
			//RootNavigation.navigate('Login', { routeName: redirectRoute });
		// }).catch(err => {

		// });
	}
	if(error.response.status == 422){
		// alert(error.response.data.error);
		//alertMessagePopupErr('error', 'Validation Error', error.response.data)
      	return;
	}
};

export function signin(data){

	return axios.post(API_URL + 'login', data)
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		// // console.log('Error  Login:', err)
		handleError(err);
		return Promise.reject(err);
  	});

}

export function add_edit_contractor(data){

    // return setAxiosDefault().then(r => {
	// 	return axios.post(API_URL + 'add-edit-contractor', data)
	//   	.then( response => {
	//   		console.log(response.data);
	//   		return response.data;
	//   	})
	//   	.catch( err => {
	// 		// // console.log('Error  Login:', err)
	// 		// handleError(err);
	// 		handleError(err);
	// 		return Promise.reject(err);
	//   	});
  	// });
	let token = getToken()
	return axios.post(API_URL + 'add-edit-contractor', data, {
        headers : {
            'Content-Type': "multipart/form-data",
			'Authorization': token
        }
    })
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		// // console.log('Error  Login:', err)
		 handleError(err);
		return Promise.reject(err);
  	});

}

export function getContractor(data, query, sortBy){

 let offset = (data-1)*10

	let token = getToken()
	return axios.get(API_URL + `get-contractor?offset=${offset}&query=${query}&sort=${sortBy}`, {
		headers: {
			'Authorization': token
		}
	})
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
	
		handleError(err);
		return Promise.reject(err);
  	});

}

export function getSearchContractor(contractorId){

   
	   let token = getToken()
	   return axios.get(API_URL + `get-all-contractor?query=${contractorId}`, {
		   headers: {
			   'Authorization': token
		   }
	   })
		 .then( response => {
			 console.log(response.data);
			 return response.data;
		 })
		 .catch( err => {
	   
		   handleError(err);
		   return Promise.reject(err);
		 });
   
   }

export function getSingleContractor(data){
   
    let token = getToken()
	   return axios.get(API_URL + `get-contractor/${data}`, {
		headers: {
			'Authorization': token
		}
	   })
		 .then( response => {
			 console.log(response.data);
			 return response.data;
		 })
		 .catch( err => {
	   
		   handleError(err);
		   return Promise.reject(err);
		 });
   
   }

   export function verifyContractor(data, status){
     
    let token = getToken()
	   return axios.put(API_URL + `update-contractor-verify-status/${data}/${status}`,{}, {
		headers: {
			'Authorization': token
		}
	   })
		 .then( response => {
			 console.log(response.data);
			 return response.data;
		 })
		 .catch( err => {
	   
		   handleError(err);
		   return Promise.reject(err);
		 });
   
   }
   

export function deleteContractor(data){

	let token = getToken()
	return axios.delete(API_URL + `delete-contractor/${data}`, {
		headers: {
			'Authorization': token
		}
	})
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});

}

export function updateContractorStatus(id, status){
	
	let token = getToken()
	return axios.put(API_URL + `update-contractor-status/${id}/${status}`,{}, {
		headers: {
			'Authorization': token
		}
	})
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});

}

export function getContractorEmployee(page, id){

	let offset = (page-1)*10
    let token = getToken()
	return axios.get(API_URL + `get-contractor-employee?offset=${offset}&contractor_id=${id}`, {
		headers: {
			'Authorization': token
		}
	})
	  .then( response => {
		  console.log(response.data);
		  return response.data;
	  })
	  .catch( err => {
	
		handleError(err);
		return Promise.reject(err);
	  });

}

export function getContractorProject(page, id, query){

	let offset = (page-1)*10
    let token = getToken()
	return axios.get(API_URL + `get-contractor-project?offset=${offset}&contractor_id=${id}&query=${query}`, {
		headers: {
			'Authorization': token
		}
	})
	  .then( response => {
		  console.log(response.data);
		  return response.data;
	  })
	  .catch( err => {
	
		handleError(err);
		return Promise.reject(err);
	  });

}

export function add_edit_project(data){

	let token = getToken()
	return axios.post(API_URL + 'add-edit-project', data, {
        headers : {
            'Content-Type': "multipart/form-data",
			'Authorization': token
        }
    })
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		// // console.log('Error  Login:', err)
		 handleError(err);
		return Promise.reject(err);
  	});

}

export function getProject(data){

	let offset = (data.page-1)*24;
	console.log(offset);
	let token = getToken()
	return axios.get(API_URL + `get-project?offset=${offset}&filter=${data.filter}&roof=${data.roof_type}&sort=${data.sortBy}&search=${data.search}&contractor_id=${data.contractor_id}&date_type=${data.date_type}&date_from=${data.date_from}&date_to=${data.date_to}`, {
		headers: {
			'Authorization': token
		}
	})
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});

}

export function deleteProject(data){

	let token = getToken()
	return axios.delete(API_URL + `delete-project/${data}`, {
		headers: {
			'Authorization': token
		}
	})
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
	
		handleError(err);
		return Promise.reject(err);
  	});

}

export function getProjectById(data){
	let token = getToken()
	return axios.get(API_URL + `get-project/${data}`,{
		headers:{
			'Authorization': token
		}
	})
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});

}

export function get_project_employee(id, page){
	let offset = (page-1)*10
	let token = getToken()
	return axios.get(API_URL + `get-project-employee/${id}?offset=${offset}`,{
		headers:{
			'Authorization': token
		}
	})
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});

}

export function get_project_feedback(id, roofId, page){
	let offset = (page-1)*10
	let token = getToken()
	return axios.get(API_URL + `get-project-feedback/${id}/${roofId}?offset=${offset}`,{
		headers:{
			'Authorization': token
		}
	})
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});

}

export function get_all_project(){
	
	let token = getToken()
	return axios.get(API_URL + `get-all-project`,{
		headers:{
			'Authorization': token
		}
	})
  	.then( response => {
  		//console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});

}


export function getRoofType(page){
	let offset = (page-1)*10
	let token = getToken()
	return axios.get(API_URL + `get-roof-type?offset=${offset}`, {
		headers:{
			'Authorization': token
		}
	})
  	.then( response => {
  		//console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});

}

export function getRoofSubCategory(data){
    let page = 1;
	let offset = (page-1)*10
	let token = getToken()
	return axios.get(API_URL + `get-roof-type-sub-category?offset=${offset}`, {
		headers: {
			'Authorization': token
		}
	})
  	.then( response => {
  		//console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});

}

export function add_roof_type(data){

	let token = getToken()
	return axios.post(API_URL + `add-edit-roof-type`, data, {
        headers : {
            'Content-Type': "application/json",
			'Authorization': token
        }
    })
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		// // console.log('Error  Login:', err)
		 handleError(err);
		return Promise.reject(err);
  	});

}


export function publishProjectApi(data){

	let token = getToken()
	return axios.post(API_URL + `publish-project`, data, {
        headers : {
            'Content-Type': "application/json",
			'Authorization': token
        }
    })
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		// // console.log('Error  Login:', err)
		 handleError(err);
		return Promise.reject(err);
  	});

}

export function add_roof_type_sub_category(data){

	let token = getToken()
	return axios.post(API_URL + `add-edit-roof-type-sub-category`, data, {
        headers : {
            'Content-Type': "application/json",
			'Authorization': token
        }
    })
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		// // console.log('Error  Login:', err)
		 handleError(err);
		return Promise.reject(err);
  	});

}

export function addProjectRoofData(data){
	let token = getToken()
	return axios.post(API_URL + 'add-project-roof-data', data, {
        headers : {
            'Content-Type': "multipart/form-data",
			'Authorization': token
        }
    })
  	.then( response => {
  		//console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		// // console.log('Error  Login:', err)
		 handleError(err);
		return Promise.reject(err);
  	});

}

export function editProjectRoofData(data){
	let token = getToken()
	return axios.post(API_URL + 'edit-project-roof-data', data, {
        headers : {
            'Content-Type': "multipart/form-data",
			'Authorization': token
        }
    })
  	.then( response => {
  		//console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		// // console.log('Error  Login:', err)
		 handleError(err);
		return Promise.reject(err);
  	});

}

export function add_project_contractor(data, projectId){

	let token = getToken();

	return axios.post(API_URL + `add-project-contractor/${projectId}`, data, {
		headers: {
			'Content-Type': "application/json",
			'Authorization': token
		}
	} )
  	.then( response => {
  		console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});

}

export function get_contractor_video(page, title, sort){
	let offset = (page-1)*10
	let token = getToken()
	return axios.get(API_URL + `get-contractor-video?offset=${offset}&title=${title}&sort=${sort}`, {
		headers: {
			'Authorization': token
		}
	} )
  	.then( response => {
  		//console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});


}

export function get_employee_video(page, title, sort){
	let offset = (page-1)*10
	let token = getToken()
	return axios.get(API_URL + `get-employee-video?offset=${offset}&title=${title}&sort=${sort}`, {
		headers: {
			'Authorization': token
		}
	} )
  	.then( response => {
  		//console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});


}

export function add_contractor_video(data){
	
	let token = getToken()
	return axios.post(API_URL + `add-edit-contractor-video`, data,{
		headers: {
			'Content-Type': "multipart/form-data",
			'Authorization': token
		}
	} )
  	.then( response => {
  		//console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});
}

export function add_employee_video(data){
	
	let token = getToken()
	return axios.post(API_URL + `add-edit-employee-video`, data,{
		headers: {
			'Content-Type': "multipart/form-data",
			'Authorization': token
		}
	} )
  	.then( response => {
  		//console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});


}

export function delete_employee_video(id){
	
	let token = getToken()
	return axios.delete(API_URL + `delete-employee-video/${id}`, {
		headers: {
			'Authorization': token
		}
	} )
  	.then( response => {
  		//console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});


}
export function delete_contractor_video(id){
	
	let token = getToken()
	return axios.delete(API_URL + `delete-contractor-video/${id}`, {
		headers: {
			'Authorization': token
		}
	} )
  	.then( response => {
  		//console.log(response.data);
  		return response.data;
  	})
  	.catch( err => {
		
		handleError(err);
		return Promise.reject(err);
  	});


}


function getToken() {
    return localStorage.getItem('lava20_admin');
  }


  